import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';

import CartIndicator from 'shared/components/CartIndicator.jsx';
import ClientEventHeader from 'event_mgmt/display/components/_ClientEventHeader.jsx';
import EventLarge from 'event_mgmt/display/components/_EventLarge.jsx';
import EventSmall from 'event_mgmt/display/components/_EventSmall.jsx';
import PurchaseDrawer from 'event_mgmt/display/components/purchase_drawer/PurchaseDrawer.jsx';
import PurchaseDrawerActions from 'event_mgmt/display/actions/PurchaseDrawerActions';
import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import SchedulingDrawer from 'shared/components/SchedulingDrawer.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import WaitlistDrawer from 'event_mgmt/display/components/waitlist_drawer/WaitlistDrawer.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { removeHash } from 'shared/utils/RouteUtils';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

class Event extends React.Component {
  state = {
    waitlistDrawerOpen: /wdo/.test(window.location.hash),
  };

  constructor(props) {
    super(props);
    removeHash();
  }

  getActiveEventDiscounts() {
    const {
      athletes: { allAthletes },
      membershipDiscountStore,
    } = this.props;
    const activeMembershipIds = allAthletes.map(a => a.active_membership_id);

    return membershipDiscountStore
      .findByEventId(this.customerEvent().id)
      .filter(med => activeMembershipIds.includes(med.membership_id));
  }

  getAuthorizedProfiles() {
    const event = this.customerEvent();
    const {
      athletes: { allAthletes },
    } = this.props;
    if (event.isMembershipExclusive()) {
      return allAthletes.filter(a => a.hasAccessTo(event));
    }
    return allAthletes;
  }

  getCurrentRegistrations() {
    const {
      registrations: { allRegistrations },
    } = this.props;

    return allRegistrations;
  }

  getEnrolledAthletes() {
    const {
      athletes: { allAthletes },
    } = this.props;
    const athleteIds = this.getCurrentRegistrations().map(
      cr => cr.customer_user_id
    );
    return allAthletes
      .filter(athlete => athleteIds.includes(athlete.id))
      .toSet();
  }

  handleWaitlistClick = () => {
    const { waitlistDrawerOpen } = this.state;
    this.setState({
      waitlistDrawerOpen: !waitlistDrawerOpen,
    });
  };

  getClientCredits() {
    const { clientCreditStore } = this.props;
    const { clientCredits } = clientCreditStore;

    return clientCredits.get(this.customerEvent().id, Map());
  }

  shouldSpin = () => {
    const {
      event: { isLoadingEvent },
      cart: { isLoadingCart },
      registrations: { isLoading },
      sessionDataStore: { isLoading: isSessionsLoading },
    } = this.props;
    const isCartLoading = currentUser().isClient() && isLoadingCart;

    return isLoadingEvent || isLoading || isSessionsLoading || isCartLoading;
  };

  pricingAutomations() {
    const {
      automationTemplateDescriptions: { descriptions },
      event: { customerEvent },
    } = this.props;
    return descriptions.filter(
      atd =>
        atd.event_id === customerEvent.id &&
        (atd.template_type === 'PerSessionPricingTemplate' ||
          atd.template_type === 'RecurringPaymentPlanTemplate')
    );
  }

  hasSpotsRemaining() {
    const {
      pdRegistrationPackageStore: { rawSessionAvailability },
    } = this.props;
    if (this.isOpenBooking()) {
      return true;
    }

    // dont use pdRegistrationPackageStore.allowsingleSessionPurchase here.  This causes a loading order issue see UH-1491
    if (this.customerEvent().allow_single_session_purchase) {
      return (
        rawSessionAvailability &&
        rawSessionAvailability.some(s => s.get('spots_remaining') > 0)
      );
    }
    const { event } = this.props;
    return event.spots_remaining > 0;
  }

  hasRequiredMembership() {
    const {
      athletes: { allAthletes },
    } = this.props;
    return (
      !this.customerEvent().isMembershipExclusive() ||
      allAthletes.some(c => c.hasAccessTo(this.customerEvent()))
    );
  }

  isAvailableForPurchase() {
    if (this.customerEvent().isFixedSchedule()) {
      const schedule = this.customerEvent().schedules.first();
      return (
        this.hasRequiredMembership() &&
        this.hasSpotsRemaining() &&
        !schedule.hasDeadlineReached()
      );
    }
    return this.hasRequiredMembership() && this.hasSpotsRemaining();
  }

  isPurchased() {
    const {
      registrations: { allRegistrations },
    } = this.props;
    return !!allRegistrations.size;
  }

  hasGenderOrAgeRestriction() {
    const schedule = this.customerEvent().schedules.first();
    return (
      schedule.gender_restriction ||
      schedule.min_age ||
      schedule.max_age ||
      schedule.date_of_birth?.hasDobRestriction()
    );
  }

  isOpenBooking() {
    return this.customerEvent().isOpenBooking();
  }

  isWaitlisted() {
    const {
      athletes: { allAthletes },
      waitlistStore: { waitlist },
    } = this.props;
    const waitlistIds = waitlist
      .map(entry => entry.get('customer_user_id'))
      .toSet();

    return (
      !waitlistIds.isEmpty() || allAthletes.some(a => waitlistIds.has(a.id))
    );
  }

  isAlmostFull() {
    return this.hasRequiredMembership() && this.customerEvent().isAlmostFull();
  }

  isAvailableForWaitlist() {
    return (
      this.hasRequiredMembership() &&
      this.customerEvent().allow_waitlist &&
      this.customerEvent().spots_remaining < 1
    );
  }

  customerEvent() {
    const {
      event: { customerEvent },
    } = this.props;
    return customerEvent;
  }

  render() {
    const headerStyles = smallScreen()
      ? {}
      : {
          maxWidth: '50rem',
          margin: '0 30rem 0 3rem',
        };
    const { waitlistDrawerOpen } = this.state;
    const {
      registrations: { allRegistrations },
      eventStaffStore: { staff },
      sessionDataStore: { sessions },
      clientDataStore: { clients },
      registrationPackageDataStore: { purchasedCounts },
      waitlistStore,
      availableTimesStore,
      membershipDiscountStore,
      purchaseDrawerOpen,
      pdRegistrationPackageStore,
      sspStore,
      cart,
      event,
      athletes,
      eventStaffStore,
    } = this.props;

    const { sessionsLoading, sessionsHasMore } = event;
    const { fixedScheduleSessions, customerEvent } = event;
    const loadedSessions = sessions.filter(
      session => session.event_id === customerEvent.id
    );
    const showLoadMore = loadedSessions.size < fixedScheduleSessions.size;
    const shouldOpenExternalUrl =
      this.customerEvent().isFixedSchedule() &&
      this.customerEvent().external_url &&
      enabledCustomerFeatures(['external_event_url']);

    const purchaseHandler = () => {
      if (shouldOpenExternalUrl) {
        return () => window.open(this.customerEvent().external_url, '_blank');
      }

      return this.isOpenBooking()
        ? SchedulingDrawerActions.clientSelected
        : PurchaseDrawerActions.drawerOpened;
    };

    return (
      <>
        <SchedulingDrawer />
        <SpinWhileLoading spinWhile="shouldSpin" shouldSpin={this.shouldSpin()}>
          <section>
            <CartIndicator
              clientView
              cart={cart.cart}
              eventId={this.customerEvent().id}
            />

            <ClientEventHeader
              almostFull={this.isAlmostFull()}
              automations={this.pricingAutomations()}
              containerStyle={headerStyles}
              event={this.customerEvent()}
              sessions={sessions}
              isAvailableForPurchase={this.isAvailableForPurchase()}
              isAvailableForWaitlist={this.isAvailableForWaitlist()}
              onPurchaseClick={purchaseHandler()}
              onWaitlistClick={this.handleWaitlistClick}
              purchased={this.isPurchased()}
              spotsRemaining={event.spots_remaining}
              style={{ paddingBottom: 10 }}
            />

            <ResponsiveElement
              largeScreen={
                <EventLarge
                  className="iphone-x-content"
                  activeEventDiscounts={this.getActiveEventDiscounts()}
                  almostFull={this.isAlmostFull()}
                  athleteStore={athletes}
                  automations={this.pricingAutomations()}
                  clientCredits={this.getClientCredits()}
                  currentRegistrations={this.getCurrentRegistrations()}
                  enrolledAthletes={this.getEnrolledAthletes()}
                  event={this.customerEvent()}
                  eventStaffStore={eventStaffStore}
                  hasGenderOrAgeRestriction={this.hasGenderOrAgeRestriction()}
                  isAvailableForPurchase={this.isAvailableForPurchase()}
                  isAvailableForWaitlist={this.isAvailableForWaitlist()}
                  onPurchaseClick={purchaseHandler()}
                  onWaitlistClick={this.handleWaitlistClick}
                  purchased={this.isPurchased()}
                  registrations={allRegistrations}
                  sessions={sessions}
                  clients={clients}
                  sessionsHasMore={sessionsHasMore}
                  sessionsLoading={sessionsLoading}
                  spotsRemaining={event.spots_remaining}
                  waitlist={waitlistStore.waitlist}
                />
              }
              smallScreen={
                <EventSmall
                  className="iphone-x-content"
                  activeEventDiscounts={this.getActiveEventDiscounts()}
                  athleteStore={athletes}
                  clientCredits={this.getClientCredits()}
                  currentRegistrations={this.getCurrentRegistrations()}
                  enrolledAthletes={this.getEnrolledAthletes()}
                  event={this.customerEvent()}
                  eventStaffStore={eventStaffStore}
                  hasGenderOrAgeRestriction={this.hasGenderOrAgeRestriction()}
                  purchased={this.isPurchased()}
                  registrations={allRegistrations}
                  sessions={sessions}
                  clients={clients}
                  waitlist={waitlistStore.waitlist}
                  waitlisted={this.isWaitlisted()}
                  sessionsHasMore={sessionsHasMore}
                  sessionsLoading={sessionsLoading}
                />
              }
            />

            <PurchaseDrawer
              athletes={this.getAuthorizedProfiles()}
              cart={cart}
              event={event}
              purchasedCounts={purchasedCounts.get(
                event.customerEvent.id,
                Map()
              )}
              availableTimesStore={availableTimesStore}
              membershipDiscountStore={membershipDiscountStore}
              onRequestClose={() => PurchaseDrawerActions.drawerClosed()}
              open={purchaseDrawerOpen}
              pdRegistrationPackageStore={pdRegistrationPackageStore}
              sspStore={sspStore}
              staff={staff}
              sessions={sessions}
              sessionsShowMore={showLoadMore}
              sessionsLoadingMore={sessionsLoading}
              onSessionsLoadMore={() => EventActions.loadMoreSessions()}
              width={
                smallScreen() ? window.innerWidth * 0.9 : STANDARD_DRAWER_WIDTH
              }
            />

            {this.isAvailableForWaitlist() && (
              <WaitlistDrawer
                open={waitlistDrawerOpen}
                athletes={this.getAuthorizedProfiles()}
                event={event}
                onRequestClose={() =>
                  this.setState({ waitlistDrawerOpen: false })
                }
                pdRegistrationPackageStore={pdRegistrationPackageStore}
                waitlistStore={waitlistStore}
                width={
                  smallScreen()
                    ? window.innerWidth * 0.9
                    : STANDARD_DRAWER_WIDTH
                }
              />
            )}
          </section>
        </SpinWhileLoading>
      </>
    );
  }
}

export default injectIntl(Event);
